import React, { useState, Fragment } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Section, Image } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import { ListItem } from '../slices/TwoColsTextList/components';
import { ReactComponent as CtaArrowRightPink } from '../images/icons/arrow-right-pink.svg';
import HeadlineText from '../slices/HeadlineText';
import * as styles from './blog.module.scss';

const IS_BROWSER = typeof window !== 'undefined';

const Blog = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });

  const [filterBy, setFilterBy] = useState('view all');
  const blogPosts = data?.posts?.nodes;
  const headerData = data?.header?.nodes;
  const filterByItems = ['VIEW ALL', 'PRESS', 'AWARDS'];

  const [_headerData] = headerData || {};
  const { headline, cta_text: ctaText, cta_link: ctaLink } = _headerData?.data;

  const site = process.env.GATSBY_SITE_NAME;

  const filteredBlogPosts = blogPosts?.filter((post) => {
    const pageType = post?.data?.page_type?.toLowerCase();
    const filterParams = site === pageType || pageType === 'both' || !pageType;

    if (filterParams) {
      return post;
    }

    return false;
  });

  const _data = {
    primary: {
      cta_link: ctaLink,
      cta_text: ctaText,
      headline_text: headline,
    },
  };

  const isFilms = process.env.GATSBY_SITE_NAME === 'films';

  let count = 0;

  const seo = {
    title: 'Latest Stuff',
    desc: headline?.text,
  };

  const bgElements = {
    blackPinkGraf: true,
    smileyRight: true,
  };

  return (
    <Layout location={location} isFilms={isFilms} handCursor seo={seo} bgElements={bgElements}>
      <HeadlineText data={_data} />
      <Section containerClassName={styles.container} className={styles.containerClass} sliceName="Blog Template">
        <div className={styles.filterRow}>
          {filterByItems?.map((item) => (
            <Fragment key={item}>
              {filterBy === item?.toLowerCase() && (
                <CtaArrowRightPink height={20} width={20} className={styles.arrowRight} />
              )}
              <a
                className={`${styles?.filterItem} ${filterBy === item?.toLowerCase() ? styles.activeFilter : ''}`}
                onClick={() => setFilterBy(item.toLowerCase())}
                role="button"
                tabIndex={0}
              >
                {item}
              </a>
            </Fragment>
          ))}
        </div>
        {filteredBlogPosts?.map((item, index) => {
          const _items = {
            content: item?.data?.description,
            cta_link: { url: item?.url },
            cta_text: { text: 'CHECK IT OUT' },
            title: item?.data?.body?.[0]?.primary?.title,
            date: item?.data?.date,
          };

          const filteredPosts = item?.data?.[filterBy] || filterBy === 'view all';

          if (filteredPosts) {
            // eslint-disable-next-line no-plusplus
            count++;
          }

          return (
            <Fragment key={item?.title?.text}>
              {filteredPosts && (
                <div className={styles.row}>
                  <div className={styles.image}>
                    <Image image={item?.data?.body?.[0]?.primary?.image} />
                  </div>
                  <div className={styles.columnText}>
                    <ListItem item={_items} firstItem={index === 0} />
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
        {count === 0 && (
          <div className={styles.downState}>
            <span className={styles.downStateText}>No posts yet for this category :(</span>
          </div>
        )}
      </Section>
      <StaticImage
        src="../images/bg_assets/GRAFFITI-PINK-GREY.png"
        className="grey-pink-graffiti-img"
        placeholder="none"
        alt="Pink Graffiti"
      />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query blogPosts {
    header: allPrismicBlog {
      nodes {
        data {
          cta_link {
            url
          }
          cta_text {
            html
            text
          }
          headline {
            html
            text
          }
        }
      }
    }
    posts: allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
      nodes {
        last_publication_date
        url
        id
        prismicId
        data {
          date
          page_type
          description
          awards
          insights
          press
          body {
            ...LargeImageTextFragmentBlogPost
            ...TwoColsTextCtaFragmentBlogPost
            ...TwoColsTextListFragmentBlogPost
          }
        }
      }
    }
  }
`;
